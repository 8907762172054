import React, { ReactElement, PropsWithChildren } from 'react'
import { Route as DomRoute, Routes as DomRoutes } from 'react-router-dom'
import { withSentryReactRouterV6Routing } from '@sentry/react'
import { Helmet } from 'react-helmet'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon'
import useMe from 'hooks/useMe'
import Authenticated from 'compositions/Authenticated'
import { TodosBadgeProps } from 'components/Todos/TodosBadge'
import Unauthorized from './Unauthorized'
import { SchoolLocation, VendorLocation } from 'graphql/schema/graphql'

export interface RouteDefinition {
  path: string
  element: ReactElement
  name: string
  parent?: string
  pathMatch?: string
  icon?: OverridableComponent<SvgIconTypeMap<object, "svg">>
  role?: string
  level?: number
  public?: boolean
  internal?: boolean
  navMenu?: boolean
  badgeComponent?: React.JSXElementConstructor<Omit<TodosBadgeProps, 'todos'>>
}

export type RouteDefinitions = RouteDefinition[]

export interface RoutesProps {
  appName: string
  allowed: boolean
  routes: RouteDefinitions
  location?: SchoolLocation | VendorLocation
}

const SentryRoutes = withSentryReactRouterV6Routing(DomRoutes)

const RouteElement: React.FC<{ appName: string, route: RouteDefinition }> = ({ appName, route }) => {
  return <>
    <Helmet titleTemplate={`%s | ${appName}`}>
      <title>{ route.name }</title>
    </Helmet>
    { route.element }
  </>
}

const Route: React.FC<{ appName: string, route: RouteDefinition, location?: SchoolLocation | VendorLocation }> = ({ appName, route, location }) => {
  const { hasRole, hasLevel } = useMe()

  if (route.role) {
    if (route.internal && !hasRole(route.role))
      return <Unauthorized />

    if (!hasRole(route.role, location))
      return <Unauthorized />
  }

  if (route.level) {
    if (route.internal && !hasLevel(route.level))
      return <Unauthorized />

    if (!hasLevel(route.level, location))
      return <Unauthorized />
  }

  if (route.public)
    return <RouteElement route={route} appName={appName} />

  return <Authenticated>
    <RouteElement route={route} appName={appName} />
  </Authenticated>
}

export const Routes: React.FC<PropsWithChildren<RoutesProps>> = ({ children, appName, allowed, routes, location }) => {
  return <SentryRoutes>
    { allowed && <>
      { routes.map((route) =>
        <DomRoute key={route.path} path={route.path} element={<Route route={route} location={location} appName={appName} />} />
      ) }
    </> }

    { children }
  </SentryRoutes>
}

export default Routes
